import React from 'react';
import useBreakpointView from 'utils/useBreakpointView';
import usePodcastImages from 'hooks/usePodcastImages';
import SocialSharing from 'components/SocialSharing';
import WebsiteImage from 'components/WebsiteImage';
import AushaPlayer from '../AushaPlayer';
import { SectionContainer } from './styles';

const PodcastDetailHeroSection = ({
  backgroundColor,
  episode,
  subTitle,
  title,
  created_date: createdDate,
  coverPhoto,
  lybsynPodcast,
  setStickyHeader,
  aushaEpisodeId,
  pageUrl,
  ...otherProps
}) => {
  const data = usePodcastImages();
  const podcastHero = data[0].node.image?.file.url;
  const isTablet = useBreakpointView(['sm', 'md']);
  const isMobile = useBreakpointView(['xs']);

  return (
    <SectionContainer backgroundColor={backgroundColor} {...otherProps}>
      <div className="section-podcast-detail-hero--wrapper">
        <div className="section-podcast-detail-hero--container">
          <div className="section-podcast-detail-hero--main-container">
            {!isMobile ? (
              <>
                <div className="section-podcast-detail-hero--image-side">
                  <div className="section-podcast-detail-hero--image-container">
                    <WebsiteImage
                      loading="eager"
                      className="section-podcast-detail-hero--image"
                      image={coverPhoto?.image || podcastHero}
                    />
                  </div>
                </div>
                <div className="section-podcast-detail-hero--text-side">
                  <p className="section-podcast-detail-hero--subtitle">{subTitle}</p>
                  <h1 className="section-podcast-detail-hero--title font-h2">{title}</h1>
                  <p className="section-podcast-detail-hero--date">{createdDate}</p>
                  <SocialSharing placement="mobile" pageUrl={pageUrl} />
                  {aushaEpisodeId && !isTablet && <AushaPlayer episodeId={aushaEpisodeId} />}
                </div>
              </>
            ) : (
              <>
                <h1 className="section-podcast-detail-hero--title font-h2">{title}</h1>
                <div className="section-podcast-detail-hero--image-side">
                  <div className="section-podcast-detail-hero--image-container">
                    <WebsiteImage
                      loading="eager"
                      className="section-podcast-detail-hero--image"
                      image={coverPhoto?.image || podcastHero}
                    />
                  </div>
                </div>
                <div className="ausha-player-tablet">
                  <AushaPlayer episodeId={aushaEpisodeId} />
                </div>
                <div className="section-podcast-detail-hero--text-side">
                  <p className="section-podcast-detail-hero--date">{createdDate}</p>
                  <SocialSharing placement="mobile" pageUrl={pageUrl} />
                </div>
              </>
            )}
          </div>
          {aushaEpisodeId && isTablet && (
            <div className="ausha-player-tablet">
              <AushaPlayer episodeId={aushaEpisodeId} />
            </div>
          )}
        </div>
      </div>
    </SectionContainer>
  );
};

export default PodcastDetailHeroSection;
