import styled from 'styled-components';

export const SectionContainer = styled.div`
  padding-top: 140px;
  margin-top: -70px;
  background: ${(props) => props.backgroundColor};

  .section-podcast-detail-hero--container {
    padding: 50px 20px;
    max-width: var(--max-width);
    margin: 0 auto;
    text-align: center;
    overflow: visible;

    .section-podcast-detail-hero--crumb {
      display: flex;
      align-items: center;

      .section-podcast-detail-hero--crumb-icon {
        padding: 0 13px;
      }

      .section-podcast-detail-hero--crumb-item {
        font-weight: var(--font-weight-500);
        font-size: var(--font-size-20);
        line-height: var(--line-height-150);
        color: var(--grey-8);
        margin: 0;
        display: flex;

        &.cur-sel {
          color: var(--darkest);
        }
      }

      .section-podcast-detail-hero--crumb-headset {
        width: 22px;
        margin-left: 10px;

        path {
          fill: var(--grey-8);
        }
      }
    }

    .section-podcast-detail-hero--main-container {
      display: flex;
      overflow: visible;

      .section-podcast-detail-hero--image-side {
        position: relative;

        .section-podcast-detail-hero--image {
          width: 372px;
          box-shadow: 0px 30px 60px rgba(11, 26, 33, 0.15);
        }
      }

      .section-podcast-detail-hero--text-side {
        padding-left: 40px;

        .section-podcast-detail-hero--subtitle {
          font-weight: var(--font-weight-900);
          font-size: var(--font-size-16);
          line-height: var(--line-height-110);
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: var(--titan-blue-4);
          text-align: left;
        }

        .section-podcast-detail-hero--title {
          text-align: left;
          margin: 16px auto 0;
        }

        .section-podcast-detail-hero--date {
          font-weight: var(--font-weight-500);
          font-size: var(--font-size-13);
          line-height: var(--line-height-150);
          text-align: left;
          color: var(--grey-8);
          margin: 10px auto 15px;
        }

        .section-podcast-detail-hero--player {
          background: white;
          margin-top: 50px;
          box-shadow: 0px 3px 10px rgba(11, 26, 33, 0.08);

          .section-layout--inner-container {
            padding-top: 33px;
            padding-bottom: 33px;
          }
        }

        @media (max-width: 1515px) {
          .social-sharing-container {
            margin-bottom: 20px;
          }
        }
      }
    }

    .section-podcast-detail-hero--favorite-container {
      max-width: 990px;
      margin: 100px auto 50px;

      .section-podcast-detail-hero--favorite-title {
        font-weight: var(--font-weight-900);
        font-size: var(--font-size-25);
        line-height: var(--line-height-120);
        text-align: center;
        letter-spacing: var(--letter-spacing--0-02);
        color: var(--darkest);
      }

      .section-podcast-detail-hero--favorite-logo-container {
        max-width: 806px;
        display: flex;
        justify-content: space-between;
        margin: 36px auto 0;

        .section-podcast-detail-hero--favorite-logo {
          padding: 10px;

          .section-podcast-detail-hero--favorite-logo-img {
            width: 74px;
            height: auto;
            max-width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .section-podcast-detail-hero--container {
      .section-podcast-detail-hero--crumb {
        i {
          font-size: var(--font-size-16);
        }

        .section-podcast-detail-hero--crumb-item {
          font-size: var(--font-size-16);
        }
      }

      .section-podcast-detail-hero--main-container {
        .section-podcast-detail-hero--image-side {
          .section-podcast-detail-hero--image {
            width: 272px;
          }

          .section-podcast-detail-hero--detail-button {
            font-size: var(--font-size-16);
            padding: 8px 24px;
          }
        }

        .section-podcast-detail-hero--text-side {
          padding-left: 30px;

          .section-podcast-detail-hero--title {
            font-size: var(--font-size-22);
            letter-spacing: var(--letter-spacing--0-02);
          }
        }
      }

      .section-podcast-detail-hero--favorite-container {
        margin: 60px auto 40px;

        .section-podcast-detail-hero--favorite-title {
          font-size: var(--font-size-20);
        }

        .section-podcast-detail-hero--favorite-logo-container {
          max-width: 100%;
          margin: 25px auto 0;

          .section-podcast-detail-hero--favorite-logo {
            .section-podcast-detail-hero--favorite-logo-img {
              width: 50px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 576px) and (max-width: 991px) {
    .ausha-player-tablet {
      margin-top: 30px;
    }
  }

  @media (max-width: 575px) {
    .section-podcast-detail-hero--container {
      .section-podcast-detail-hero--main-container {
        flex-direction: column;

        .ausha-player-tablet {
          iframe {
            margin-top: 10px;
            margin-left: -10px !important;
            width: calc(100% + 20px) !important;
          }
        }
        .section-podcast-detail-hero--image-side {
          .section-podcast-detail-hero--image {
            width: 100%;
          }
        }

        .section-podcast-detail-hero--title {
          font-size: var(--font-size-28);
          letter-spacing: var(--letter-spacing--0-02);
          text-align: left;
        }
        .section-podcast-detail-hero--text-side {
          padding: 20px 0 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .section-podcast-detail-hero--date {
            margin: 0px;
            font-size: var(--font-size-16);
          }
          .social-sharing-container {
            width: auto;
            margin: 0;
            button > img {
              margin: 0;
              width: 42px;
            }
          }
        }
      }

      .section-podcast-detail-hero--favorite-container {
        .section-podcast-detail-hero--favorite-title {
          display: none;
        }

        .section-podcast-detail-hero--favorite-logo-container {
          flex-wrap: wrap;
          justify-content: center;
        }
      }
    }
  }
`;
