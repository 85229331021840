/* eslint-disable one-var */
import React, { useState } from 'react';
import isNil from 'utils/isNil';
import HyperLink from 'components/Core/HyperLink';
import StyledRichText from 'components/RichText/StyledRichText';
import ContentfulEmbedCode from 'components/Contentful/ContentfulEmbedCode';
import usePodcastDetailContentSectionImages from 'hooks/usePodcastDetailContentSectionImages';
import SummarySection from 'components/Sections/SummarySection';
import SocialSharing from 'components/SocialSharing';
import SidebarV2 from 'components/Sections/Sidebar/SidebarV2';
import { SectionContainer } from './styles';

const PodcastDetailContentSection = ({
  overview,
  episodeSections = [],
  summary,
  relatedVideos = [],
  sideBarRecommended,
  pageUrl,
  series,
  ...otherProps
}) => {
  const [isSidebarSticky, setIsSidebarSticky] = useState(true);
  episodeSections = episodeSections || [];
  relatedVideos = relatedVideos || [];
  const pageImages = usePodcastDetailContentSectionImages();

  let podcastMore;
  let favSpotify, favPodcast, favYoutubePodcast, favOrange, favSticher, favPandora, favFeed;
  pageImages.forEach((imageItem) => {
    if (imageItem.node.internalName === 'podcast-detail-content-more') {
      podcastMore = !isNil(imageItem.node.image) ? imageItem.node.image.file.url : '';
    } else if (imageItem.node.internalName === 'podcast-favorite-spotify') {
      favSpotify = !isNil(imageItem.node.image) ? imageItem.node.image.file.url : '';
    } else if (imageItem.node.internalName === 'podcast-favorite-podcast') {
      favPodcast = !isNil(imageItem.node.image) ? imageItem.node.image.file.url : '';
    } else if (imageItem.node.internalName === 'podcast-favorite-youtube-music') {
      favYoutubePodcast = !isNil(imageItem.node.image) ? imageItem.node.image.file.url : '';
    } else if (imageItem.node.internalName === 'podcast-favorite-orange') {
      favOrange = !isNil(imageItem.node.image) ? imageItem.node.image.file.url : '';
    } else if (imageItem.node.internalName === 'podcast-favorite-sticher') {
      favSticher = !isNil(imageItem.node.image) ? imageItem.node.image.file.url : '';
    } else if (imageItem.node.internalName === 'podcast-favorite-pandora') {
      favPandora = !isNil(imageItem.node.image) ? imageItem.node.image.file.url : '';
    } else if (imageItem.node.internalName === 'podcast-favorite-feed') {
      favFeed = !isNil(imageItem.node.image) ? imageItem.node.image.file.url : '';
    }
  });

  const otherPlatforms = [
    {
      title: 'Podcast',
      logo: favPodcast,
      link:
        otherProps.applePodcastsLink ||
        'https://podcasts.apple.com/us/podcast/toolbox-for-the-trades/id1503078938',
    },
    {
      title: 'Spotify',
      logo: favSpotify,
      link:
        otherProps.spotifyLink ||
        'https://open.spotify.com/show/76wMZ1SJV4OAql9HzbnQ87?si=66s37hbqRpu_4vQCaB1T3w',
    },
    {
      title: 'YouTube Music',
      logo: favYoutubePodcast,
      link:
        otherProps.youtubePodcastLink || series === 'Toolbox for the Trades'
          ? 'https://music.youtube.com/playlist?list=PLfbEYZ7T-01ZSOJOB9DYDlOW8RRkEv77- '
          : 'https://www.youtube.com/playlist?list=PLfbEYZ7T-01ZYOCqrLdnXKVBSuA6J3U1q',
    },
  ];

  const moreContents = [
    {
      img: podcastMore,
      title: 'This is a title of a featured content ',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      subtitle: 'Education • 21  minutes',
      link: '/',
    },
    {
      img: podcastMore,
      title: 'This is a title of a featured content ',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      subtitle: 'Education • 21  minutes',
      link: '/',
    },
  ];

  const nextPost =
    sideBarRecommended && sideBarRecommended.length > 0 && sideBarRecommended[0].node;

  return (
    <SectionContainer {...otherProps}>
      <div className="section-podcast-detail-content--wrapper">
        <div className="section-podcast-detail-content--container">
          <SocialSharing placement="blog" isSticky={isSidebarSticky} />
          <div className="section-podcast-detail-content--contents">
            <div className="section-podcast-detail-content--main-contents">
              <div className="section-podcast-detail-content--main-text-area">
                <div className="section-podcast-detail-content--overview-area">
                  <h2 className="section-podcast-detail-content--content-title">
                    Episode Overview
                  </h2>
                  {overview && <StyledRichText content={overview} />}
                </div>

                {episodeSections && episodeSections.length > 0 && (
                  <div className="section-podcast-detail-content--reference-area">
                    <h3>Key Takeaways</h3>
                    {episodeSections.map((section, index) => {
                      const filteredId = section?.anchorId?.replace('.', '-');
                      return (
                        <HyperLink
                          className="section-podcast-detail-content--reference"
                          href={`#${filteredId}`}
                          key={index}
                        >
                          {section.sectionTitle}
                        </HyperLink>
                      );
                    })}
                  </div>
                )}

                {episodeSections && episodeSections.length > 0 && (
                  <div className="section-podcast-detail-content--content-area">
                    {episodeSections.map((section, index) => {
                      const filteredId = section?.anchorId?.replace('.', '-');
                      return (
                        <div
                          className="section-podcast-detail-content--content-section-area"
                          id={`${filteredId}`}
                          key={index}
                        >
                          <StyledRichText content={section.content} />
                        </div>
                      );
                    })}
                  </div>
                )}

                {relatedVideos?.embedCode && (
                  <div className="section-podcast-detail-content--video-area">
                    <h2 className="section-podcast-detail-content--video-title">Related Videos</h2>
                    <ContentfulEmbedCode {...relatedVideos} />
                  </div>
                )}
              </div>
              {/* TODO: once Person content model is updated this can be reintroduced */}
              {false && (
                <div className="section-podcast-detail-content--more-area">
                  <h2 className="section-podcast-detail-content--more-title">
                    More content from John Doe
                  </h2>
                  {moreContents.map((item, index) => {
                    return (
                      <HyperLink
                        className="section-podcast-detail-content--more-item"
                        href={item.link}
                        key={index}
                      >
                        <img
                          className="section-podcast-detail-content--more-item-img"
                          src={item.img}
                          alt="podcast-detail"
                        />
                        <div className="section-podcast-detail-content--more-item-text">
                          <p className="section-podcast-detail-content--more-item-title">
                            {item.title}
                          </p>
                          <p className="section-podcast-detail-content--more-item-description">
                            {item.description}
                          </p>
                          <p className="section-podcast-detail-content--more-item-subtitle">
                            {item.subtitle}
                          </p>
                        </div>
                      </HyperLink>
                    );
                  })}
                </div>
              )}

              {(summary?.headline || summary?.subhead || summary?.cta) && (
                <SummarySection
                  className="test-summary-section"
                  headline={summary?.headline}
                  subHead={summary?.subhead}
                  callToAction={summary?.cta}
                />
              )}
            </div>

            <div className="section-podcast-detail-content--sidebar-contents">
              <SidebarV2
                podcast={{
                  post: nextPost,
                  otherPlatforms,
                }}
                customCta={
                  series === 'Mastering ServiceTitan'
                    ? {
                        title: 'Over 7,500 contractors use ServiceTitan for their businesses.',
                        bullet1: 'Pro users see 67% higher growth in jobs booked than non-users',
                        bullet2: 'Automate marketing, job booking, dispatching, and more',
                        cta: {
                          buttonLabel: 'Get Pro Products',
                          type: 'var(--critical-3)',
                          url: '/features/pro',
                          buttonClass: 'upgrade-to-pro',
                        },
                      }
                    : null
                }
                demo
                email2
                setIsSidebarSticky={setIsSidebarSticky}
              />
            </div>
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

export default PodcastDetailContentSection;
