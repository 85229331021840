import { graphql, useStaticQuery } from 'gatsby';

const usePodcastDetailContentSectionImages = () => {
  const { allContentfulImage } = useStaticQuery(graphql`
    query {
      allContentfulImage(
        filter: {
          contentful_id: {
            in: [
              "EEIWDwVxCD1KyKqilwzVE" #podcast-mark
              "20sgasl5SeY0CrbqfqjEOb" #podcast-detail-content-more
              "3c6QsgzhIBl3kbaz7o9SDJ" #podcast-favorite-spotify
              "2tJ0HouccbJrmiZ5Vi4wOK" #podcast-favorite-podcast
              "5xdGqxVtDHkgnxH90iMUTL" #podcast-favorite-google-podcast
              "27psQeg4B68PBAREykYDlT" #podcast-favorite-youtube-music
              "6f3gTR4AChAINJ5NCO06PR" #podcast-favorite-google-play
              "4uHAi6mWVDkTCaSnBSFIjc" #podcast-favorite-orange
              "36Z3G6vYJKS44QxY2jeu6D" #podcast-favorite-sticher
              "2wcpElYYtB7SmuVGDviXJ6" #podcast-favorite-pandora
              "3VgFyqkUstDKMlpSP5GOAJ" #podcast-favorite-feed
            ]
          }
        }
      ) {
        edges {
          node {
            internalName
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);

  return allContentfulImage?.edges;
};

export default usePodcastDetailContentSectionImages;
