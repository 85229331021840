/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const SectionContainer = styled.div`
  .section-podcast-detail-content--wrapper {
    background: var(--white);
    padding: 50px 20px;
    margin: 0 auto;
    max-width: var(--max-width);
  }

  .section-podcast-detail-content--container {
    .social-sharing-container {
      margin-left: 0;
    }
    .section-podcast-detail-content--contents {
      display: flex;

      .section-podcast-detail-content--main-contents {
        flex: auto;
        padding-right: 80px;
        width: 50%;

        .section-podcast-detail-content--main-text-area {
          .section-podcast-detail-content--content-title {
            font-size: var(--font-size-25);
          }

          .section-podcast-detail-content--content-description {
            font-size: var(--font-size-18);
          }

          .section-podcast-detail-content--overview-area {
            margin-bottom: 50px;

            p {
              font-weight: normal;
              font-size: var(--font-size-16);
            }
          }

          .section-podcast-detail-content--reference-area {
            display: flex;
            flex-direction: column;
            padding: 40px 40px 22px;
            background: var(--grey-1);
            }

            h3 {
              color: var(--darkest);
              font-weight: var(--font-weight-700);
              font-size: var(--font-size-18);
              line-height: var(--line-height-150);
              margin-bottom: 22px;
            }

            .section-podcast-detail-content--reference {
              font-weight: var(--font-weight-500);
              font-size: var(--font-size-18);
              line-height: var(--line-height-150);
              margin-bottom: 18px;
              color: var(--titan-blue-3);
            }
          }

          .section-podcast-detail-content--content-area {
            .section-podcast-detail-content--content-section-area {
              padding-top: 15px;

              p {
                font-weight: normal;
                font-size: var(--font-size-16);
              }
            }
          }

          .section-podcast-detail-content--video-area {
            margin-top: 60px;

            .section-podcast-detail-content--video-title {
              font-size: var(--font-size-25);
            }
            .section-layout--inner-container {
              width: auto;
              max-width: 846px;
              padding: 30px 0 50px;

              iframe {
                aspect-ratio: 1;
                height: auto !important;
              }
            }
          }
        }

        .section-podcast-detail-content--more-area {
          .section-podcast-detail-content--more-title {
            font-size: var(--font-size-25);
            line-height: var(--line-height-120);
            letter-spacing: var(--letter-spacing--0-02);
            margin: 54px 0 30px;
          }

          .section-podcast-detail-content--more-item {
            display: flex;
            padding: 24px 0;
            border-bottom: 1px solid var(--grey-3);

            &:last-child {
              border-bottom: none;
            }

            .section-podcast-detail-content--more-item-img {
            }

            .section-podcast-detail-content--more-item-text {
              padding-left: 30px;

              .section-podcast-detail-content--more-item-title {
                font-weight: var(--font-weight-700);
                font-size: var(--font-size-20);
                line-height: var(--line-height-120);
                letter-spacing: var(--letter-spacing--0-01);
                color: var(--darkest);
                margin: 0;
              }
              .section-podcast-detail-content--more-item-description {
                font-weight: var(--font-weight-500);
                font-size: var(--font-size-16);
                line-height: var(--line-height-150);
                color: var(--darkest);
                margin: 12px 0 0;
              }
              .section-podcast-detail-content--more-item-subtitle {
                font-weight: var(--font-weight-500);
                font-size: var(--font-size-13);
                line-height: var(--line-height-150);
                text-transform: uppercase;
                color: var(--grey-6);
                margin: 12px 0 0;
              }
            }
          }
        }

        .section-summary--wrapper {
          margin-top: 36px;
        }
      }
      .section-podcast-detail-content--sidebar-contents {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        min-width: 340px;

        & > div {
          width: 100%;
        }

        .button--link {
          height: 48px;
          width: 100%;
          font-size: var(--font-size-16);
        }

        .section-podcast-detail-content--sidebar-title {
          font-weight: var(--font-weight-900);
          font-size: var(--font-size-25);
          line-height: var(--line-height-120);
          letter-spacing: var(--letter-spacing--0-02);
        }

        .podcast-detail--subscribe-form {
          display: block;
          margin-top: 20px;
          width: 100%;

          input {
            font-size: var(--font-size-16) !important;
            height: 58px !important;
            box-shadow: none;
          }
          button {
            background-color: var(--titan-blue-3);
            font-size: var(--font-size-16);
            line-height: var(--line-height-110);
            text-align: center;
            letter-spacing: var(--letter-spacing--0-01);
            color: var(--white);
            margin-left: 0;
            width: 100%;
            box-shadow: none;
          }
        }

        .section-podcast-detail-content--sidebar-others {
          margin-top: 40px;
          margin-bottom: 60px;

          .section-podcast-detail-content--sidebar-others-item {
            padding: 16px 0;
            display: flex;
            align-items: center;
            border-bottom: 1px solid var(--grey-3);

            .section-podcast-detail-content--sidebar-others-item-img {
              width: 30px;
              margin-right: 16px;
            }

            .section-podcast-detail-content--sidebar-others-item-title {
              font-weight: var(--font-weight-500);
              font-size: var(--font-size-16);
              line-height: var(--line-height-150);
              color: var(--darkest);
              margin: 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: 935px) {
    .section-podcast-detail-content--container {
      .section-podcast-detail-content--contents {
        flex-direction: column;

        .section-podcast-detail-content--main-contents {
          width: 100%;
          padding-right: 0;
        }

        .section-podcast-detail-content--sidebar-contents {
          width: 100%;
          max-width: 500px;
          min-width: 0;
          padding-top: 50px;
          align-items: start;
          margin: 0 auto;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .section-podcast-detail-content--container {
      .section-podcast-detail-content--contents {
        .section-podcast-detail-content--main-contents {
          .section-podcast-detail-content--main-text-area {
            .section-podcast-detail-content--reference-area {
              padding: 20px 20px 4px;

              .section-podcast-detail-content--reference {
                font-size: var(--font-size-16);
              }
            }
          }

          .section-podcast-detail-content--more-area {
            .section-podcast-detail-content--more-title {
              font-size: var(--font-size-20);
            }
            .section-podcast-detail-content--more-item {
              padding: 20px 0;

              .section-podcast-detail-content--more-item-img {
                width: 85px;
              }

              .section-podcast-detail-content--more-item-text {
                padding-left: 20px;

                .section-podcast-detail-content--more-item-title {
                  font-size: var(--font-size-18);
                }

                .section-podcast-detail-content--more-item-description {
                  display: none;
                }

                .section-podcast-detail-content--more-item-subtitle {
                  font-size: var(--font-size-13);
                  margin-top: 5px;
                }
              }
            }
          }

          .section-podcast-detail-content--start-area {
            flex-direction: column;
            padding: 30px 20px;

            .section-podcast-detail-content--start-text {
              padding-left: 0;
              padding-top: 20px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    .section-podcast-detail-content--container {
      .section-podcast-detail-content--contents {
        .section-podcast-detail-content--sidebar-contents {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
`;
