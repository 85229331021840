import { graphql, useStaticQuery } from 'gatsby';

const usePodcastEpisodes = (entryId, seriesType) => {
  const { allContentfulPodcast } = useStaticQuery(graphql`
    query {
      allContentfulPodcast(
        filter: { series: { eq: "Toolbox for the Trades" } }
        sort: { fields: [podcastDate], order: DESC }
      ) {
        edges {
          node {
            id
            podcastTitle
            podcastDate(locale: "")
            seo {
              pageUrl
              taxonomy {
                industries
                topics
              }
            }
            series
            minutes

            podExcerpt {
              podExcerpt
            }
            excerpt {
              raw
            }
            cardPhoto {
              image {
                ...Image
              }
            }
            coverPhoto {
              image {
                ...Image
              }
            }
          }
        }
      }
    }
  `);

  const match = allContentfulPodcast.edges.filter(
    ({ node }) =>
      (entryId === undefined || node.id !== entryId) &&
      (seriesType === undefined || node.series === seriesType),
  );

  return match;
};

export default usePodcastEpisodes;
