import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/Core/Button';
import StyledRichText from 'components/RichText/StyledRichText';
import WebsiteImage from 'components/WebsiteImage';

const PodcastAboutShowSection = ({ content, ...otherProps }) => {
  return (
    <SectionContainer {...otherProps}>
      <div className="section-podcast-show--container">
        <div className="section-podcast-show--main-container">
          <div className="section-podcast-show--text-side">
            <h2 className="section-podcast-show--title font-h5">{content.headline}</h2>
            <div className="section-podcast-show--description">
              <StyledRichText content={content.subhead} />
            </div>
            <Button
              shadow={false}
              className="section-podcast-show--button"
              to={content?.cta?.url}
              type="secondary"
            >
              {content?.cta?.buttonLabel}
            </Button>
          </div>
          <div className="section-podcast-show--image-side">
            <div className="section-podcast-show--image-container">
              <WebsiteImage
                className="section-podcast-show--image"
                image={content.backgroundImage}
              />
            </div>
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

PodcastAboutShowSection.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  description: PropTypes.string,
  descriptionColor: PropTypes.string,
  backgroundImg: PropTypes.string,
  backgroundColor: PropTypes.string,
  buttonTitle: PropTypes.string,
  buttonType: PropTypes.string,
  buttonLinkTo: PropTypes.string,
};

const SectionContainer = styled.div`
  padding: 50px 20px;
  max-width: var(--max-width);
  margin: 0 auto;
  background: white;

  .section-podcast-show--container {
    text-align: center;
    overflow: visible;

    .section-podcast-show--main-container {
      display: flex;
      justify-content: space-between;

      .section-podcast-show--image-side {
        position: relative;

        .section-podcast-show--image {
          max-width: 100%;
          width: 400px;
        }
      }

      .section-podcast-show--text-side {
        flex: 7;
        max-width: 100%;
        text-align: left;
        padding-right: 80px;

        .section-podcast-show--title {
          text-align: left;
          margin: 0 auto;
        }

        .section-podcast-show--description p {
          text-align: left;
          margin: 24px auto 0;
        }

        .section-podcast-show--button {
          padding: 8px 24px;
          font-size: var(--font-size-16);
          margin-top: 36px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .section-podcast-show--container {
      .section-podcast-show--main-container {
        .section-podcast-show--image-side {
          .section-podcast-show--image {
            width: 272px;
          }
        }

        .section-podcast-show--text-side {
          padding-right: 30px;

          .section-podcast-show--title {
            font-size: var(--font-size-h5);
          }

          .section-podcast-show--button {
            margin-top: 30px;
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    .section-podcast-show--container {
      .section-podcast-show--main-container {
        flex-direction: column-reverse;

        .section-podcast-show--image-side {
          .section-podcast-show--image {
            width: 100%;
          }
        }

        .section-podcast-show--text-side {
          padding-right: 0;
          padding-top: 0;

          .section-podcast-show--title {
            display: none;
          }
        }
      }
    }
  }
`;

export default PodcastAboutShowSection;
