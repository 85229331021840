import React, { createRef } from 'react';

const AushaPlayer = ({ episodeId, series }) => {
  return (
    <>
      <iframe
        frameBorder="0"
        loading="lazy"
        id="ausha-mzCM"
        height="220"
        style={{ border: 'none', width: '100%', height: '220px', marginLeft: '-15px' }}
        src={`https://player.ausha.co/index.html?showId=${
          series === 'Toolbox for the Trades' ? 'ZgVxDHZXjeev' : 'PAMnXiJdv0Vx'
        }&display=horizontal&color=%2322252A&podcastId=${episodeId}&v=3&playerId=ausha-4U7e`}
      ></iframe>
      <script src="https://player.ausha.co/ausha-player.js"></script>
    </>
  );
};

export default AushaPlayer;
