import React from 'react';
import styled from 'styled-components';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { STGlyphTertiary } from 'components/Brand/Logos/STGlyph';
import RichTextHyperLink from 'components/Core/HyperLink/RichTextHyperLink';
import ContentfulCallToAction from 'components/Contentful/ContentfulCallToAction';
import { reduceContentValues } from 'utils/text';

const SummarySection = ({ headline, subHead, callToAction, backgroundImage, ...otherProps }) => {
  const backImg = backgroundImage?.file?.url;
  const type = callToAction?.buttonLabel === 'Get Demo' ? 'primary' : 'secondary';

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => {
        return (
          <RichTextHyperLink href={node.data.uri}>
            {reduceContentValues(node?.content)}
          </RichTextHyperLink>
        );
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <p className="blocks-paragraph">{children}</p>;
      },
    },
  };

  return (
    <SectionContainer backgroundImage={backImg} {...otherProps}>
      <div className="section-summary--wrapper">
        <div className="section-summary--container">
          <div className="section-summary--logo">
            <STGlyphTertiary width="60px" height="55" />
          </div>
          <div className="section-summary--text">
            <p className="section-summary--heading">{headline}</p>
            <div className="section-summary--description">
              {subHead?.raw && renderRichText(subHead, options)}
            </div>
          </div>
          <ContentfulCallToAction
            buttonClass="section-summary--button"
            type={type}
            shadow={false}
            url={callToAction?.url}
            buttonLabel={callToAction?.buttonLabel}
            modalForm={callToAction?.modalForm}
            useModalForm={callToAction?.modalForm}
          />
        </div>
      </div>
    </SectionContainer>
  );
};

const SectionContainer = styled.div`
  .section-summary--wrapper {
    background: var(--white);
    margin-top: 40px;
    filter: drop-shadow(0px 15px 40px rgba(11, 26, 33, 0.25));
  }

  .section-summary--container {
    margin: 0 auto;
    padding: 32px;
    background: var(--neutral-1l-1);
    display: flex;
    background-image: cover;
    background-position: top right;
    justify-content: space-between;
    background-image: url(${(props) => props.backgroundImage});

    .section-summary--logo {
      min-width: 60px;
      max-width: 60px;
    }

    .section-summary--text {
      padding-left: 25px;
      max-width: 70%;

      .section-summary--description {
        font-size: var(--font-size-18);
        line-height: var(--line-height-150);
        margin: 0;
        font-weight: var(--font-weight-500);
      }

      .blocks-paragraph {
        font-size: var(--font-size-16);
        font-weight: normal;
        margin-top: 0;
        margin-bottom: 16px;
      }

      .section-summary--heading {
        font-size: var(--font-size-20) !important;
        font-weight: var(--font-weight-700) !important;
        line-height: var(--line-height-150);
        margin: 0;
      }
    }
    .section-summary--button {
      display: inline-flex;
      font-size: var(--font-size-16);
      line-height: var(--line-height-110);
      padding: 16px 24px;
      margin-left: auto;
      height: min-content;
    }
  }

  @media (max-width: 935px) {
    .section-summary--container {
    }
  }

  @media (max-width: 767px) {
    .section-summary--container {
      flex-direction: column;
      padding: 30px;

      .section-summary--text {
        padding-left: 0;
        padding-top: 20px;
        max-width: 100%;
      }
      .section-summary--button {
        margin-left: 0px;
      }
    }
  }

  @media (max-width: 575px) {
    .section-summary--container {
      .section-summary--text {
        max-width: initial;
      }
      .section-summary--button {
        width: 100%;
      }
    }
  }
`;

export default SummarySection;
