import { graphql } from 'gatsby';
import React from 'react';
import DetailHeroSection from 'components/Page/Podcast/DetailHeroSection';
import AboutShowSection from 'components/Page/Podcast/AboutShowSection';
import DetailContentSection from 'components/Page/Podcast/DetailContentSection';
import ContentfulCardsBlock from 'components/Contentful/ContentfulCardsBlock';
import { truncateText } from 'utils/text';
import podcastIcon from 'images/podcast-gray.svg';
import { format } from 'utils/date';
import ToolboxLayout from 'layouts/toolboxLayout';
import usePodcastEpisodes from 'hooks/usePodcastEpisodes';
import BookmarkContext from 'utils/BookmarkContext';

const PodcastDetailPage = (props) => {
  const {
    id,
    podcastTitle,
    podcastDate,
    seo: seoData,
    series,
    minutes,
    coverPhoto,
    cardPhoto,
    episode,
    aushaEpisodeId,
    relatedVideos,
    overview,
    summary,
    episodeSections,
    aboutTheShow,
    spotifyLink,
    applePodcastsLink,
    googlePodcastLink,
  } = props.data.contentfulPodcast;
  const { pageContext } = props;

  const { taxonomy } = seoData || {};
  const moreEpisodes = usePodcastEpisodes(id, series);
  const topics = taxonomy?.topics || ['All Topics'];

  const data = moreEpisodes
    ? Array.isArray(moreEpisodes)
      ? moreEpisodes
      : [moreEpisodes]
    : moreEpisodes;

  const cards = {
    title: 'More episodes',
    description: '',
    bgColor: 'Alice Blue',
    cardType: 'Image',
    bodyType: 'simple',
    cards: data
      ? data.slice(0, 3).map((epi) => {
          const podcastExcerpt =
            epi.node?.podExcerpt?.podExcerpt || epi.node?.excerpt?.content[0]?.content[0]?.value;
          const descriptionTrunc = truncateText(podcastExcerpt, 90);

          return {
            subTitle: `${epi?.node?.seo?.taxonomy?.topics?.join(' • ')} • ${
              epi?.node?.minutes
            } minutes`,
            title: epi?.node?.podcastTitle,
            description: {
              description: descriptionTrunc,
            },
            cover: epi?.node?.cardPhoto?.image || epi?.node?.coverPhoto?.image,
            ctaLink: epi?.node?.seo?.pageUrl,
          };
        })
      : [],
  };

  const subTitle = `${topics.join(' • ')} • ${minutes} minutes`;
  const breadcrumbs = {
    data: [
      {
        title: 'Toolbox',
        icon: null,
        url: '/toolbox',
      },
      {
        title: 'Podcasts',
        icon: podcastIcon,
        url: '/podcasts',
        hideInMobile: true,
      },
      {
        title: series,
        icon: null,
        url: pageContext.url,
      },
      {
        title: episode,
        icon: null,
        url: null,
      },
    ],
    absolute: true,
    theme: 'dark',
  };

  const bookmarkData = {
    type: 'Podcast',
    url: props.path,
    title: podcastTitle,
    subTitle,
    image: cardPhoto?.image || coverPhoto?.image,
  };

  return (
    <BookmarkContext.Provider value={bookmarkData}>
      <ToolboxLayout breadcrumbs={breadcrumbs} contentfulSeo={seoData} layout="toolbox" {...props}>
        <DetailHeroSection
          backgroundColor="var(--skyline-1)"
          subTitle={subTitle}
          title={`${podcastTitle}`}
          created_date={format(podcastDate, 'MMMM D, YYYY')}
          coverPhoto={coverPhoto}
          episode={episode !== null && episode !== '' ? `Episode ${episode}` : 'Bonus Episode'}
          aushaEpisodeId={aushaEpisodeId}
          pageUrl={seoData.pageUrl}
        />

        <DetailContentSection
          overview={overview}
          episodeSections={episodeSections}
          summary={summary}
          relatedVideos={relatedVideos}
          applePodcastsLink={applePodcastsLink}
          googlePodcastLink={googlePodcastLink}
          spotifyLink={spotifyLink}
          sideBarRecommended={moreEpisodes}
          pageUrl={seoData.pageUrl}
          series={pageContext.series}
        />

        {moreEpisodes && moreEpisodes.length > 0 && (
          <ContentfulCardsBlock cols={3} {...cards} textColor="black" />
        )}

        <AboutShowSection content={aboutTheShow} />
      </ToolboxLayout>
    </BookmarkContext.Provider>
  );
};

export default PodcastDetailPage;

export const PodcastDetailPageQuery = graphql`
  query PodcastBySlug($slug: String!) {
    contentfulPodcast(seo: { pageUrl: { eq: $slug } }) {
      ...Podcast
    }
  }
`;
